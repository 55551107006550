import React, { Component } from "react";

import shape from '../assets/images/services-shape.svg';
import shape1 from '../assets/images/services-shape-1.svg';

import listaServicos from '../assets/js/data/servicos.json';

class Servicos extends Component {
    render() {
        const servicos = listaServicos.servicos;
        return (
            <section id="servicos" className="services-area pt-120" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-title text-center pb-40">
                                <div className="line m-auto"></div>
                                <h3 className="title">
                                    <span>Somos comprometidos com a qualidade de vida de nossos clientes:</span><br/>
                                    Conheça nossos serviços
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    {Object.keys(servicos).map((v, i) => 
                        <div key={'servico-'+servicos[v].titulo} className="col-lg-4 col-md-7 col-sm-8">
                            <div className="single-services text-center mt-30" data-aos="fade-up" data-aos-duration={servicos[v].tempo} data-aos-delay={servicos[v].delay}>
                                <div className="services-icon">
                                    <img fetchpriority="low" className="shape" src={shape} alt="shape" />
                                    <img fetchpriority="low" className="shape-1" src={shape1} alt="shape" />
                                    <i className={servicos[v].imagem}></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#!" dangerouslySetInnerHTML={ {__html: servicos[v].titulo} } /></h4>
                                    <p className="text">{servicos[v].subtitulo}</p>
                                    {/* <a className="more" href="#">Saiba mais <i className="lni-chevron-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </section>
        );
    }
}

export default Servicos;
