import React, { Component } from "react";

// import water from '../assets/images/water.webp';
// import shape from '../assets/images/about-shape-1.svg';

class Sobre extends Component {
    render() {
        return (
            <section id="sobre" className="about-area pt-70" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/*<div className="about-content mt-50LeftBig">*/}
                            <div className="mt-50LeftBig">
                                <div className="section-title">
                                    <div className="line"></div>
                                    <h3 className="title">Quem somos <span>e o que fazemos</span></h3>
                                </div>
                                <p className="text">
                                    Somos uma empresa especializada no comércio, manutenção e instalação de purificadores, bebedouros, filtros e demais produtos para purificação de água.
                                </p>
                                <p className="text">
                                    Situada na cidade de Londrina, atendemos toda a região: com qualidade, preços justos e acompanhamento diferenciado.
                                </p>
                                <p className="text">
                                    Entre em contato conosco, ou faça-nos uma visita (Avenida Bandeirantes, 1066) – estaremos prontos para melhor lhe atender!
                                </p>
                            </div>
                        </div>
                        {/*<div className="col-lg-6">*/}
                        {/*    <div className="about-image text-center mt-50RightBig">*/}
                        {/*        <img fetchpriority="low" src={water} height="400" alt="Água pura é benefício pra sua saude" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                {/*<div className="about-shape-1">*/}
                {/*    <img fetchpriority="low" src={shape} alt="shape" />*/}
                {/*</div>*/}
            </section>
        );
    }
}

export default Sobre;
