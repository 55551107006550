import React, { Component } from "react";
// import AOS from 'aos';

import './template/actions/metrics';

// import Loading from "./template/elements/Loading";
import Highlight from "./template/Highlight";
// import Soft from "./template/Soft";
// import Produtos from "./template/Produtos";
import Marcas from "./template/Marcas";
import Servicos from "./template/Servicos";
import Sobre from "./template/Sobre";
import SobreProdutos from "./template/SobreProdutos";
import Atendimento from "./template/Atendimento";
// import Ambientes from "./template/Ambientes";
import Depoimentos from "./template/Depoimentos";
import Footer from "./template/elements/Footer";

import './assets/scss/style.scss';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

class App extends Component {

  render() {
    
    // AOS.init();

    return (
      <div className="App">
        {/*<Loading />*/}
        <Highlight />
        {/*<Soft />*/}
        {/*<Produtos />*/}
        <Marcas />
        <Servicos />
        {/*<Ambientes />*/}
        <Sobre />
        <SobreProdutos />
        <Atendimento />
        <Depoimentos />
        <Footer />
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}
        <a href="#home" className="back-to-top"><i className="lni lni-chevron-up"></i></a>
      </div>
    );
  }
}

export default App;
