import React, { Component } from "react";
import Image from 'react-image-webp';

import listaMarcas from '../assets/js/data/marcas.json';

class Marcas extends Component {
    render() {
        const marcas = listaMarcas.marcas;
        return (
            <div className="brand-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="brand-logo d-flex align-items-center justify-content-center justify-content-md-between">
                            {Object.keys(marcas).map((v, i) => 
                                <div key={'marca-'+marcas[v].nome} className="single-logo mt-30" data-aos="fade-up" data-aos-duration={marcas[v].tempo} data-aos-delay={marcas[v].delay}>
                                    <Image src={ require('../assets/images/marcas' + marcas[v].imagem) } webp={ require('../assets/images/webp' + marcas[v].webp) } alt={marcas[v].nome} title={marcas[v].nome} />
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Marcas;
