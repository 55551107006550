import React, { Component } from "react";
import Image from 'react-image-webp';
import $ from 'jquery';

import logo from '../../assets/images/logo-color.png';
    import logowebp from '../../assets/images/webp/logo-color.webp';
import whats from '../../assets/images/whatsapp-logo.png';
    import whatswebp from '../../assets/images/webp/whatsapp-logo.webp';

class Nav extends Component {
    
    componentDidMount(){

        var scrollLink = $('.page-scroll');
        // Active link switching
        $(window).scroll(function () {
            var scrollbarLocation = $(this).scrollTop();
    
            scrollLink.each(function () {
    
                var sectionOffset = $(this.hash).offset().top - 73;
    
                if (sectionOffset <= scrollbarLocation) {
                    $(this).parent().addClass('active');
                    $(this).parent().siblings().removeClass('active');
                }
            });
        });    

        $(".navbar-nav a").on('click', function () {
            $(".navbar-collapse").removeClass("show");
        });
    
        $(".navbar-toggler").on('click', function () {
            $(this).toggleClass("active");
            $(".navbar-collapse").toggleClass("show");
        });
    
        $(".navbar-nav a").on('click', function () {
            $(".navbar-toggler").removeClass('active');
        });

    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">
                <a className="navbar-brand" href="#!home" data-metrics="menu,click,logo">
                    <Image src={logo} webp={logowebp} alt="Purificadores & Cia" />
                </a>
                <button className="navbar-toggler" type="button" data-metrics="menu,click,menu-mobile"
                        data-toggle="collapse" data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul id="nav" className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <a className="page-scroll" href="#home" data-metrics="menu,click,home">Home</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className="page-scroll" href="#produtos" data-metrics="menu,click,produtos">Produtos</a>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <a className="page-scroll" href="#servicos" data-metrics="menu,click,servicos">Serviços</a>
                        </li>
                        <li className="nav-item">
                            <a className="page-scroll" href="#sobre" data-metrics="menu,click,sobre">Sobre</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className="page-scroll" href="#ambientes" data-metrics="menu,click,ambientes">Ambientes</a>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <a className="page-scroll" href="#depoimentos" data-metrics="menu,click,depoimentos">Depoimentos</a>
                        </li>
                    </ul>
                </div>
                
                <div className="navbar-btn d-none d-sm-inline-block">
                    <a className="main-btn whatsapp" data-scroll-nav="0" data-metrics="menu,click,whatsapp" 
                       href="https://api.whatsapp.com/send?phone=5543999411122" rel="noopener noreferrer" target="_blank">
                        <Image src={whats} webp={whatswebp} alt="Entre em contato conosco pelo whatsapp!" height="24" />
                    </a>
                </div>
            </nav>
        );
    }
}

export default Nav;
