import React, { Component } from "react";
import Image from 'react-image-webp';

import purificadores from '../assets/images/purificadores-everest.png';
import purifwebp from '../assets/images/webp/purificadores-everest.webp';
import shape from '../assets/images/about-shape-2.svg';

class Sobre extends Component {
    render() {
        return (
            <section className="about-area pt-70" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                <div className="about-shape-2">
                    <img fetchpriority="low" src={shape} alt="shape" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-last">
                            <div className="about-content mt-50LeftBig">
                                <div className="section-title">
                                    <div className="line"></div>
                                    <h3 className="title"><span>Produtos de </span> qualidade</h3>
                                </div>
                                <p className="text">Trabalhamos com as melhores marcas do mercado – Soft, IBBL, Europa, entre outros!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-first">
                            <div className="about-image text-center mt-50RightBig">
                                <Image alt="sobre" src={purificadores} webp={purifwebp} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Sobre;
