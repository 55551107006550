import $ from 'jquery';
var gtag = window.gtag || null;

$(document).ready(function($){

    // ----------------------------------------------------------------------------------------
    // Métricas
    // ----------------------------------------------------------------------------------------

    $('[data-metrics]').on('click', function(e) {
        var metrics = $(this).attr('data-metrics');
        var metricsVars = metrics.split(',');

        var locale = typeof metricsVars[2] !== 'undefined' ? metricsVars[2] : document.location.hash.replace('#!', '');

        if (locale === '' || locale === 'home') {
            locale = 'homepage'
        }

        gtag && gtag('event', metricsVars[1], {'event_category': locale, 'event_label': metricsVars[0]});
    });

    $('[data-form-metric]').on('click', 'input', function(e) {
        var metrics = $(this).parent().find('label')
                                      .text()
                                      .toLowerCase()
                                      .replace(' *', '')
                                      .split(' ')
                                      .join('-');

        var locale = document.location.hash.replace('#!', '');

        if (locale === '' || locale === 'home') {
            locale = 'homepage'
        }

        gtag && gtag('event', metrics, {'event_category': locale, 'event_label': 'homepage'});
    });

    $('[data-form-metric]').on('click', 'button', function(e) {
        var metrics = 'enviar';

        var locale = document.location.hash.replace('#!', '');

        if (locale === '' || locale === 'home') {
            locale = 'homepage'
        }

        gtag && gtag('event', metrics, {'event_category': locale, 'event_label': 'homepage'});
    });

});