import React, { Component } from "react";
// import axios from 'axios';
// import $ from 'jquery';
//
// import 'jquery-validation';
// import './../assets/js/data/validation-pt-br';

import Nav from './elements/Nav';

// import Image from 'react-image-webp';
// import purificadores from '../assets/images/purificadores-everest.png';
import whats from '../assets/images/whatsapp-logo.png';
// import whatsWebp from '../assets/images/webp/whatsapp-logo.webp';
// import banner from '../assets/images/EVS_BannerSite_ProdutoNacional.jpg';
//     import bannerWebp from '../assets/images/webp/EVS_BannerSite_ProdutoNacional.webp';

class Highlight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sticky: "",
            // form: 'contato',
            // nome: '',
            // email: '',
            // telefone: '',
            // mensagem: '',
            // mailSentContact: false,
            // errorContact: null
        }
    }

    componentDidMount() {

        // var localState = this;

        document.addEventListener("scroll", () => {
          const getSticky = window.scrollY < 60 ? "" : "sticky";
          this.setState({ sticky: getSticky });
        });

        // $("form.form-contact").validate({
        //     submitHandler: function() {
        //         axios({
        //             method: 'post',
        //             url: process.env.REACT_APP_API_PATH,
        //             headers: { 'content-type': 'application/json' },
        //             data: localState.state
        //         }).then(result => {
        //             console.log(':)');
        //             localState.setState({
        //                 mailSentContact: result.data.sent,
        //                 errorContact: null
        //             })
        //         }).catch(error => {
        //             console.log(':(');
        //             localState.setState({
        //                 mailSentContact: false,
        //                 errorContact: error.message
        //             })
        //         });
        //     }
        // });

    }

    render() {

        return (
            <header className="header-area">
                <div className={"navbar-area " + this.state.sticky }>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Nav />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="home" className="header-hero bg_cover pb-120">
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-lg-8">*/}
                            <div className="col-lg-12">
                                <div className="header-hero-content text-center">
                                    <h3 className="header-sub-title" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                                        Nos visite em nossa loja:<br />
                                        Avenida Bandeirantes, 1066<br/>
                                        Ou ligue <a href="tel:554333291122" className="phone_button" data-metrics="highlight,click,telefone">(43) 3329-1122</a> ou
                                        <a href="https://api.whatsapp.com/send?phone=5543999411122" className="phone_button" data-metrics="highlight,click,whatsapp" rel="noopener noreferrer" target="_blank">
                                            {/* eslint-disable-next-line react/jsx-no-undef */}
                                            <img src={whats} alt="Entre em contato conosco pelo whatsapp!" height="24" /> (43) 99941-1122
                                        </a>
                                    </h3>
                                    <h2 className="header-title pt-20" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">Cuide de sua saúde!</h2>
                                    <p className="text" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">Sempre temos diversos produtos em oferta – melhores preços, melhores produtos!</p>
                                </div>
                                {/*<div className="header-hero-image text-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000">*/}
                                {/*    <Image src={banner} webp={bannerWebp} alt="Purificadores Soft" className="highlight-banner" />*/}
                                {/*</div>*/}
                            </div>
                            {/*<div className="col-lg-4">*/}
                            {/*    <div className="subscribe-form form-topo" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1200">*/}
                            {/*        <form action="#" className="form-contact">*/}
                            {/*            <input type="hidden" name="form" value={this.state.form} />*/}
                            {/*            <input type="text" name="nome" className="mb-10" placeholder="Insira seu nome" required*/}
                            {/*                   value={this.state.nome}*/}
                            {/*                   onChange={e => this.setState({ nome: e.target.value })} />*/}
                            {/*            <input type="email" name="email" className="mb-10" placeholder="Insira seu e-mail" required*/}
                            {/*                   value={this.state.email}*/}
                            {/*                   onChange={e => this.setState({ email: e.target.value })} />*/}
                            {/*            <input type="tel" name="telefone" className="mb-10" placeholder="Insira seu telefone" required*/}
                            {/*                   value={this.state.telefone}*/}
                            {/*                   onChange={e => this.setState({ telefone: e.target.value })} />*/}
                            {/*            <textarea name="mensagem" className="mb-10" placeholder="Em que podemos lhe ajudar?" required*/}
                            {/*                   value={this.state.mensagem}*/}
                            {/*                   onChange={e => this.setState({ mensagem: e.target.value })}></textarea>*/}
                            {/*            <button className="main-btn">Enviar mensagem</button>*/}
                            {/*        </form>*/}
                            {/*        <div className="post-email">*/}
                            {/*            { this.state.mailSentContact && <div className="post-email--success">Obrigado por se inscrever!</div> }*/}
                            {/*            { this.state.errorContact && <div className="post-email--error">{this.state.errorContact}</div> }*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Highlight;
