import React, { Component } from "react";
import axios from 'axios';
import Image from 'react-image-webp';

import $ from 'jquery';
import 'jquery-validation';

import logo from '../../assets/images/logo-white.png';
import webp from '../../assets/images/webp/logo-white.webp';

// import Email from '../../assets/js/vendor/smtp';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: 'newsletter',
            email: '',
            mailSent: false,
            error: null
        }
    }

    componentDidMount(){

        var localState = this;

        // Show or hide the sticky footer button
        $(window).on('scroll', function (event) {
            if ($(this).scrollTop() > 600) {
                $('.back-to-top').fadeIn(200)
            } else {
                $('.back-to-top').fadeOut(200)
            }
        });

        //Animate the scroll to yop
        $('.back-to-top').on('click', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: 0,
            }, 1500);
        });

        $("form.form-newsletter").validate({
            submitHandler: function() {
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_PATH,
                    headers: { 'content-type': 'application/json' },
                    data: localState.state
                }).then(result => {
                    console.log(':)');
                    console.log(localState);
                    localState.setState({
                        mailSent: result.data.sent
                    })
                }).catch(error => {
                    console.log(':(');
                    console.log(localState);
                    localState.setState({
                        error: error.message
                    })
                });
            }
        });
    }

    render() {
        return (
            <footer id="footer" className="footer-area pt-120">
                <div className="container pt-120">
                    {/*<div className="subscribe-area" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-lg-6">*/}
                    {/*            <div className="subscribe-content mt-45">*/}
                    {/*                <h2 className="subscribe-title">Se inscreva na newsletter <span>e receba atualizações</span></h2>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-6">*/}
                    {/*            <div className="subscribe-form mt-50">*/}
                    {/*                <form action="#" className="form-newsletter">*/}
                    {/*                    <input type="hidden" name="form" value={this.state.form} />*/}
                    {/*                    <input type="email" name="email" placeholder="Insira seu e-mail" required*/}
                    {/*                           value={this.state.email}*/}
                    {/*                           onChange={e => this.setState({ email: e.target.value })} />*/}
                    {/*                    <button className="main-btn">Inscreva-se</button>*/}
                    {/*                    <div className="post-email">*/}
                    {/*                        { this.state.mailSent && <div className="post-email--success">Obrigado por se inscrever!</div> }*/}
                    {/*                        { this.state.error && <div className="post-email--error">{ this.state.error}</div> }*/}
                    {/*                    </div>*/}
                    {/*                </form>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="footer-widget pb-100">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8">
                                <div className="footer-about mt-50" data-aos="fade-up" data-aos-duration="500" data-aos-delay="800">
                                    <a className="logo" href="#home">
                                        <Image src={logo} webp={webp} alt="Purificadores & Cia" />
                                    </a>
                                    <p className="text">
                                        Comércio, manutenção e instalação de purificadores, bebedouros, filtros e demais produtos para purificação de água.
                                    </p>
                                    <p className="text">
                                        Avenida Bandeirantes, 1066 - Ipiranga<br />
                                        Londrina - PR<br />
                                        CEP 86.010-020
                                    </p>
                                    <ul className="social">
                                        <li><a href="https://www.facebook.com/PurificadoreseCia/" data-metrics="footer,click,facebook"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="https://www.instagram.com/purificadoresecia/" data-metrics="footer,click,instagram"><i className="lni lni-instagram-filled"></i></a></li>
                                        <li><a href="https://api.whatsapp.com/send?phone=5543999411122" data-metrics="footer,click,whatsapp" rel="noopener noreferrer" target="_blank"><i className="lni lni-whatsapp"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-7 col-sm-7">
                                <div className="footer-link d-flex mt-50 justify-content-md-between">
                                    <div className="link-wrapper" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1200">
                                        <div className="footer-title">
                                            <h4 className="title">Navegue</h4>
                                        </div>
                                        <ul className="link">
                                            <li><a href="#home" data-metrics="footer,click,home">Home</a></li>
                                            <li><a href="#produtos" data-metrics="footer,click,produtos">Produtos</a></li>
                                            <li><a href="#servicos" data-metrics="footer,click,servicos">Serviços</a></li>
                                            <li><a href="#sobre" data-metrics="footer,click,sobre">Sobre</a></li>
                                            <li><a href="#depoimentos" data-metrics="footer,click,depoimentos">Depoimentos</a></li>
                                        </ul>
                                    </div>
                                    <div className="link-wrapper" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1600">

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-5 col-sm-5">
                                <div className="footer-contact mt-50" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1600">
                                    <div className="footer-title">
                                        <h4 className="title">Contatos</h4>
                                    </div>
                                    <ul className="contact link">
                                        <li><a href="tel:554333291122" data-metrics="footer,click,telefone">(43) 3329-1122</a></li>
                                        <li><a href="https://api.whatsapp.com/send?phone=5543999411122" data-metrics="footer,click,whatsapp" rel="noopener noreferrer" target="_blank">(43) 99941-1122</a></li>
                                        <li><a href="mailto:contato@purificadoresecia.com.br" data-metrics="footer,click,email">contato@purificadoresecia.com.br</a></li>
                                        <li>Londrina - PR</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright d-sm-flex justify-content-between">
                                    <div className="copyright-content">
                                        <p className="text">Desenvolvimento por <a href="https://numine.com.br" data-metrics="footer,click,numine" rel="nofollow">numine</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
