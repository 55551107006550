import React, { Component } from "react";
import Image from 'react-image-webp';

import handshake from '../assets/images/handshake.png';
import handshakeWebp from '../assets/images/webp/handshake.webp';
import shape from '../assets/images/about-shape-1.svg';

class Sobre extends Component {
    render() {
        return (
            <section className="about-area pt-70" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-content mt-50LeftBig">
                                <div className="section-title">
                                    <div className="line"></div>
                                    <h3 className="title">Ótimo atendimento <span>e</span> preço diferenciado</h3>
                                </div>
                                <p className="text">
                                    Prezamos por um atendimento diferenciado, com atenção aos detalhes e a satisfação de nossos clientes.<br />
                                    Trabalhamos com preços justos, de forma a valorizar seu investimento!
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image text-center mt-50RightBig">
                                <Image alt="Atendimento diferenciado" src={handshake} webp={handshakeWebp} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-shape-1">
                    <img fetchpriority="low" src={shape} alt="shape" />
                </div>
            </section>
        );
    }
}

export default Sobre;
