import React, { Component } from "react";
import Image from 'react-image-webp';

import listaDepoimentos from '../assets/js/data/depoimentos.json';

import shape from '../assets/images/textimonial-shape.svg';

class Depoimentos extends Component {
    render() {
        const depoimentos = listaDepoimentos.depoimentos;
        return (
            <section id="depoimentos" className="testimonial-area pt-120" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="section-title text-center pb-40">
                                <div className="line m-auto"></div>
                                <h3 className="title">Depoimentos<span> de nossos clientes</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row testimonial-activeUpBig">
                    {Object.keys(depoimentos).map((v, i) => 
                        <div key={'marca-'+depoimentos[v].nome} className="col-lg-4" data-aos="fade-up" data-aos-duration="500" data-aos-delay={depoimentos[v].delay}>
                            <div className="single-testimonial">
                                <div className="testimonial-review d-flex align-items-center justify-content-between">
                                    <div className="quota">
                                        <i className="lni lni-quotation"></i>
                                    </div>
                                    <div className="star">
                                        <ul>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="testimonial-text">
                                    <p className="text" dangerouslySetInnerHTML={ {__html: depoimentos[v].depoimento} } />
                                </div>
                                <div className="testimonial-author d-flex align-items-center">
                                    <div className="author-image">
                                        <img fetchpriority="low" className="shape" src={shape} alt="shape" />
                                        <Image className="author" src={require('../assets/images/depoimentos/'+depoimentos[v].imagem)} webp={require('../assets/images/webp/'+depoimentos[v].webp)} alt={depoimentos[v].nome} title={depoimentos[v].nome} />
                                    </div>
                                    <div className="author-content media-body">
                                        <h6 className="holder-name">{depoimentos[v].nome}</h6>
                                        <p className="text">{depoimentos[v].funcao}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </section>
        );
    }
}

export default Depoimentos;
